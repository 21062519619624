import React, { useEffect } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderInfoPage from "../components/HeaderInfoPages/HeaderInfoPages";
import { Helmet } from "react-helmet";

const SeoData = {
  Title: "Distribution | Sajdah - The World's First Smart Educational Prayer Rug",
  Keywords: [`Distribution`, `Sajdah`, `Prayer`, `Rug`, `Islam`, `Smart`],
  Description: "Distribution registration for Sajdah, the world's first smart educational prayer rug that teaches new Muslims and children the second most important pillar in Islam.",
  Image: "https://getsajdah.com/sajdah_og_image_thumb.jpg",
  url: "https://getsajdah.com/distribution",
  titleTemplate: "Distribution | Sajdah - The World's First Smart Educational Prayer Rug"
};

const Distribution = () => {
  const initializeHubspotForm = () => {
    if ('hbspt' in window) {
      document.getElementById("form-container").innerHTML = "";
      window.hbspt.forms.create({
        region: "na1",
        portalId: "19807131",
        formId: "a7e66fd2-96cf-4cdf-8fec-961316cb8d80",
        target: "#form-container"
      });
    } else {
      setTimeout(initializeHubspotForm, 100)
    }
  }
  useEffect(() => {
    initializeHubspotForm();
  });
  return (
      <Layout>
        <SEO
          description={SeoData.Description}
          keywords={SeoData.Keywords}
          title={SeoData.Title}
          image={SeoData.Image}
          url={SeoData.url}
          titleTemplate={SeoData.titleTemplate}
        />
        <HeaderInfoPage title="Distribution" />
        <Body>
          <Text>
          <h1>Please register your distribution interest details below.</h1>
          <Helmet>
              <script charset="utf-8" type="text/javascript" src="https://js.hsforms.net/forms/shell.js"></script>
          </Helmet>
          <Form id="form-container"/>
          </Text>
        </Body>
      </Layout>
  )
};

const Form = styled.div`
  width: 100%;
`;

const Body = styled.div`
  padding: 100px 0;
  background: #f5f7fd;
  width: 100%;
`;

const Text = styled.div`
  box-shadow: 0 15px 38px rgb(0 0 0 / 10%);
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 32px;
  font-family: Poppins, sans-serif;
  h5 {
    font-family: Poppins, sans-serif;
    opacity: 0.8;
  }
  p {
    font-family: Poppins, sans-serif;
    opacity: 0.8;
    line-height: 26px;
    max-width: 100%;
  }
  li {
    font-family: Poppins, sans-serif;
    opacity: 0.8;
    margin-bottom: 12px;
  }
  a {
    color: #009298;
    text-decoration: none;
    margin-right: 4px;
  }
  @media (max-width: 960px) {
    max-width: 85%;
    padding: 24px;
  }
`;

export default Distribution;
